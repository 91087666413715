var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "marketplace-filters filters-container filters table"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_c('sygni-input', {
    attrs: {
      "label": 'COMPANY NAME'
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-date-picker', {
    staticClass: "secondary border-collapse",
    attrs: {
      "label": 'TRANSACTION DATE',
      "range": true
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-select', {
    attrs: {
      "options": [{
        label: 'Registered',
        value: 'REGISTERED'
      }, {
        label: 'Unknown',
        value: 'UNKNOWN'
      }, {
        label: 'Unregistered',
        value: 'UNREGISTERED'
      }],
      "label": 'REGISTRATION STATUS'
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-date-picker', {
    staticClass: "secondary border-collapse",
    attrs: {
      "label": 'REGISTRATION DATE',
      "range": true
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-date-picker', {
    staticClass: "secondary border-collapse",
    attrs: {
      "label": 'CREATED',
      "range": true
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-date-picker', {
    staticClass: "secondary border-collapse",
    attrs: {
      "label": 'UPDATED',
      "range": true
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }), _c('div', {
    staticClass: "col-2"
  }), _c('div', {
    staticClass: "col-2"
  }), _c('div', {
    staticClass: "col-2"
  }), _c('div', {
    staticClass: "col-2"
  }, [_c('div', {
    staticClass: "filters__actions"
  }, [_c('sygni-rounded-button', {
    staticClass: "filled black",
    attrs: {
      "loading": _vm.isLoading,
      "selectable": false
    }
  }, [_vm._v(" Show results (" + _vm._s(_vm.itemsCount) + ")")]), _c('div', {
    staticClass: "clear-all-button"
  }, [_vm._v("Clear all")])], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }